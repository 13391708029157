@import '_variables';

#GF-CoverTransactionFee {
  .GF-CoverTransactionFee__checkbox,
  .GF-CoverTransactionFee__checkbox--selected {
    margin-left: -0.5rem;
    .MuiSvgIcon-root {
      font-size: 1.75rem;
    }
  }

  .GF-CoverTransactionFee__heading {
    margin-bottom: 0.25rem;
  }

  .GF-CoverTransactionFee__message {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .GF-CoverTransactionFee__value {
    font-weight: bold;
  }
}
