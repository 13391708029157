// palette
$blue: #2356f6;
$black: #020026;
$dark-blue: #17479e;
$purple: #aa23bf;
$green: #3fab8c;
$gray: #656677;
$orange: #ea9633;
$yellow: #ddba1f;
$pink: #ec67c7;
$red: #ea333e;
$dark-gray: #656677;
$light-gray: #dadce8;
$lighter-gray: #eef0fc;
// the css variable --primary-color is given a value on load of giving form, and is pulled from the current giving form config.
$primary: var(--primary-color);
$accent: var(--accent-color);
$body-font: var(--body-font);

// breakpoints
$mobile-break: 600px;

// fonts
$display-stack: Helvetica, 'Helvetica Neue', Arial, sans-serif;
$system-stack: Helvetica, sans-serif, system, -apple-system, '.SFNSText-Regular',
  'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;

// spacings
$block-margin: 1rem;
