@import '_variables';

@mixin font-styles($font-size, $font-weight) {
  color: $black;
  font-family: var(--body-font);
  margin: 0;
  font-size: $font-size;
  font-weight: $font-weight;
}

#dd-container {
  .dtd-plugin {
    .dtd-callout {
      box-shadow: none;
      border-radius: 0.5rem;
      border-color: $light-gray;

      * {
        font-family: var(--body-font);
      }

      .dtd-search-box {
        border-radius: 0.5rem;
        box-shadow: none;
        border-color: $light-gray;

        .dtd-search-input {
          border-radius: 0.5rem;
        }
      }

      .dtd-mg-eligible {
        .mg-forms-button,
        .mg-guidelines-button {
          text-transform: none;
          color: var(--accent-color);
          background-color: var(--primary-color);
          border-radius: 0.5rem;
          height: 3rem;
          font-weight: 900;
        }
      }

      .dtd-lets-check {
        button.lets-check {
          text-transform: none;
          color: var(--accent-color);
          background-color: var(--primary-color);
          border-radius: 0.5rem;
          height: 3rem;
          font-weight: 900;
        }
      }
    }
  }
}
