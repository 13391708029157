#GivingForm-DisabledOverlay {
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 10%;
  z-index: 1000;
  display: none;
  &.GivingForm-DisabledOverlay--show {
    display: block;
  }
}
