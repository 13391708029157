@import 'mixins';

.GF-RecurringGiftSvg {
  margin-right: 2.5rem;
  margin-bottom: 0;
  height: 10.5625rem;
  min-width: 8.625rem;
}
@include mobile-styles {
  .GF-RecurringGiftSvg {
    display: block;
    margin: 0 auto 1rem;
  }
}
