@import '_variables';
@import '_mixins';

.goal-meter {
  @include mobile-styles {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .meter-container {
    display: flex;

    @include mobile-styles {
      align-items: center;
      flex-direction: column;
    }
  }

  &-progress-bar {
    &.MuiLinearProgress-root {
      background-color: $lighter-gray;
    }
    border: 1px solid $primary;
    margin: 1.5rem 0;
  }

  &-circle-container {
    padding: 2rem 0;
    width: 12rem;
  }

  &-speedometer-container {
    height: 14rem;
    padding: 2rem 0;
    width: 26rem;

    .speedometer {
      .arc {
        border-radius: 50px;
        stroke-width: 1px;
        stroke: $primary;
      }
      .needle {
        stroke: $primary;
        stroke-width: 1px;
        filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.08));
      }
    }
  }

  &-circle {
    border-radius: 50%;
  }

  .amount-time-container {
    display: flex;
    justify-content: space-between;

    &.column {
      flex-direction: column;
      justify-content: center;
      padding: 0 2rem;

      .time-container {
        margin-top: 0.75rem;
      }
    }

    .time-container {
      align-items: center;
      align-self: center;
      display: flex;

      .goal-meter-time-icon {
        font-size: 2rem;
        stroke: $primary;
      }

      .goal-meter-time {
        margin-left: 0.35rem;
      }
    }
  }

  &-amounts {
    align-items: center;
    display: flex;
  }
}
.condense {
  align-items: center;
  display: flex;
  flex-direction: column;
}
