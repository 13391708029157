@import '_variables';

.tyg-block {
  .tyg-prompt {
    .MuiTypography-root {
      margin-bottom: 1rem;
    }
  }

  .tyg-info-container {
    // Support flex styling for (eventually) more TYGs
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 0.5rem;
      font-size: 1.125rem;
      line-height: 1.4rem;
    }

    .tyg-card {
      border: 2px solid transparent;
      flex: 1;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &.selected {
        border-color: $primary;
      }

      .MuiCardContent-root {
        position: relative;
      }

      img {
        height: 4rem;
        margin-bottom: 1rem;
      }

      .tyg-threshold {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
      }

      .tyg-donation-amount {
        margin-bottom: 0.5rem;
      }

      .MuiChip-root.MuiChip-outlinedPrimary.MuiChip-clickable {
        &:hover {
          background-color: $primary;
          color: $lighter-gray;
        }
      }
    }
  }
}

@media screen and (min-width: 450px) {
  .tyg-block {
    .tyg-info-container {
      flex-direction: row;
      justify-content: space-between;

      .tyg-card {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}
