#GF-CustomField {
  .GF-CustomField {
    &__heading {
      margin-bottom: 1rem;
    }
    &__field {
      &--text {
        width: 100%;
      }
      &--textarea {
        width: 100%;
      }
      &--number {
        width: 100%;
      }
      &--currency {
        width: 100%;
      }
    }
  }
}
