@import '_variables';

@mixin font-styles($font-size, $font-weight) {
  color: $black;
  font-family: var(--body-font);
  margin: 0;
  font-size: $font-size;
  font-weight: $font-weight;
}

.HP-CustomContent {
  max-width: 100%;
  font-family: var(--body-font);
  img {
    max-width: 100%;
  }
  h1 {
    @include font-styles(2rem, 900);
  }
  h2 {
    @include font-styles(1.5rem, 900);
  }
  h3 {
    @include font-styles(1.125rem, 900);
  }
  h4 {
    @include font-styles(1rem, 900);
  }
  h5 {
    @include font-styles(1rem, 700);
  }
  h6 {
    @include font-styles(0.75rem, 700);
  }
  p,
  ol,
  ul {
    color: $black;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
  }
  &:after {
    clear: both;
  }
}
