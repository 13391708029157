@import '_variables';

.TY-Custom-Content-Preview {
  .jodit-valid-tag {
    background-color: rgb(238, 240, 252);
  }

  .jodit-invalid-tag {
    background-color: $red;
  }
}
