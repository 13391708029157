@import '_variables';

.hosted-page-edit-options {
  &-wrapper {
    position: relative;
    border: 2px solid transparent;
    transition: transform 0.2s; // Transitions placement during drag and drop preview
    overflow: hidden;
    &:hover:not(.disable-hover),
    &.edit-options-wrapper--selected {
      border: 2px solid $blue;
      border-radius: 4px;
      .hosted-page-edit-options-buttons-container {
        visibility: visible;
        z-index: 2; // Without z-index value, the child block will cover up the buttons
      }
    }

    &.compress-wrapper .hosted-page-edit-options-buttons-container {
      top: -1px;
      right: -1px;
    }

    &.header-edit-wrapper {
      margin-top: 0;
    }

    .drag-icon-button {
      cursor: move;
    }

    .edit-options-icon {
      stroke: white;
    }
  }
  &-buttons-container {
    position: absolute;
    top: -13px;
    right: -12px;
    background-color: $blue;
    border-radius: 4px;
    visibility: hidden;
    & svg {
      width: 26px;
      height: 26px;
    }
  }
}
