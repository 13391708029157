.GF-PaymentSectionInputs {
  background-color: rgb(246, 246, 246);
  border-radius: 0.25rem;
  padding: 1rem;

  #GF-PaymentSectionInputs-SecureText {
    align-items: center;
    color: rgb(148, 148, 148);
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }
}
