.ProgressBar {
  .MuiLinearProgress-root {
    height: 1.2rem;
    border-radius: 44px;
  }

  .MuiLinearProgress-bar {
    border-radius: 44px;
  }
}
