@import '_variables';

.tribute {
  #tribute-modal-target {
    position: relative;
    overflow: visible !important;

    .MuiModal-root {
      position: absolute;
      right: 0;
      top: 0;
      bottom: unset;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &-prompt {
    .MuiTypography-root {
      margin-bottom: 1rem;
    }
  }

  &-preview {
    border: 1px solid $lighter-gray;
    padding: 1rem;
    position: relative;

    &-title {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;

      .MuiTypography-root {
        color: $primary;
      }

      svg {
        fill: $primary;
        margin-right: 0.5rem;
        stroke: none;
      }
    }

    .tribute-content {
      display: flex;

      .tribute-ecard-image {
        max-width: 20%;
        margin-right: 1.5rem;
      }

      .tribute-detail {
        flex: 1;

        .tribute-honoree {
          color: $gray;
          margin-bottom: 0.4rem;
        }

        .tribute-message {
          color: $gray;
          font-style: italic;
        }
      }
    }

    .tribute-preview-hover {
      background: rgba(2, 0, 38, 0.5);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 4;
      align-items: center;
      visibility: hidden;
      display: flex;
      justify-content: center;
      top: 0;
      left: 0;
    }

    &:hover {
      .tribute-preview-hover {
        visibility: visible;
      }
    }
  }
}
