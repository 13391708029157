@import '_mixins';
@import '_variables';

.GF-ACHPaymentInfo .GF-ACHPaymentInfo {
  margin-bottom: 0.5rem;

  &__heading {
    @include h5-styles;
    color: $gray;
    margin-bottom: 1rem;
  }
}
