@import '_mixins';
@import '_variables';

$block-padding-left-and-right: 3rem; // used in edit mode calculations
$EDIT_PAGE_WIDTH: 1280px; // This value is also defined in the tsx file

img {
  max-width: 100%;
}

// Special handling for the droppable zone in edit mode
@mixin side-droppable-handling($left-percentage, $width-percentage) {
  [data-rbd-droppable-id] {
    position: absolute;
    // In order to capture hover properly, need to expand the droppable area beyond the left edge of the page
    // This complicates calculations, but try to capture it all here so it's easier to adjust
    $offset: 0.5; // droppable offset
    left: percentage(-1 * $offset);
    width: percentage(1 + $offset);
    // Increase z-index to cover the giving form when side-by-side
    &.active {
      z-index: 5;
    }
    > * {
      position: relative;
      // Calculate left position accounting for the expanded width due to $offset:
      $left_val: (
          $offset/(1 + $offset) + $left-percentage / percentage(1 + $offset)
        ) * 100;

      $left_val_percentage: unquote($left_val + '%');
      left: calc(#{$left_val_percentage} + #{$block-padding-left-and-right});
      // Width calculation since to account for the expanded parent size due to $offset:
      width: calc(
        #{$width-percentage} / (1 + #{$offset}) - #{$block-padding-left-and-right} *
          2
      );
    }
  }
}

.hosted-page-blocks {
  overflow: hidden;
  width: 100%;
  background-color: white;
  &:not(.edit-mode) {
    row-gap: 1.5rem; // When in edit-mode, we expand the droppables to be flush/touching
    @include mobile-styles {
      padding: 1.5rem;
    }
  }
  &.edit-mode {
    .goal-meter {
      padding: 1.5rem 0;
    }
  }
  &.edit-mode [data-rbd-droppable-id] {
    // Set min height for top/bottom droppables.  If they are empty, this allows items to still be dropped
    // Side block min height is handles in the TSX file since it is based on the dynamic height of the giving form
    min-height: 5rem;
  }

  display: grid;
  column-gap: 1.5rem;
  grid-template-areas:
    'header header'
    'top top'
    'left right'
    'bottom bottom'
    'footer footer';
  grid-auto-rows: auto;

  // Setup for the various layouts
  .hosted-page-header {
    grid-area: header;
    position: relative;
    &.disabled {
      display: none;
    }
  }

  .hosted-page-footer {
    grid-area: footer;

    &.disabled {
      display: none;
    }
  }

  .top-blocks {
    grid-area: top;
    margin-left: $block-padding-left-and-right;
    margin-right: $block-padding-left-and-right;
  }
  .bottom-blocks {
    grid-area: bottom;
    margin-left: $block-padding-left-and-right;
    margin-right: $block-padding-left-and-right;
  }
  &.LeftThird {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    .iframe-container {
      grid-area: left;
      margin-left: $block-padding-left-and-right;
    }
    .side-blocks {
      grid-area: right;
      margin-right: $block-padding-left-and-right;
      @include side-droppable-handling(33%, 66%);
    }
  }
  &.LeftHalf {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    .iframe-container {
      grid-area: left;
      margin-left: $block-padding-left-and-right;
    }
    .side-blocks {
      grid-area: right;
      margin-right: $block-padding-left-and-right;
      @include side-droppable-handling(50%, 50%);
    }
  }
  &.RightThird {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    .side-blocks {
      grid-area: left;
      margin-left: $block-padding-left-and-right;
      @include side-droppable-handling(0%, 66%);
    }
    .iframe-container {
      grid-area: right;
      margin-right: $block-padding-left-and-right;
    }
  }
  &.RightHalf {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    .side-blocks {
      grid-area: left;
      margin-left: $block-padding-left-and-right;
      @include side-droppable-handling(0%, 50%);
    }
    .iframe-container {
      grid-area: right;
      margin-right: $block-padding-left-and-right;
    }
  }
  &.FullWidth {
    display: flex;
    flex-direction: column;
    .side-blocks {
      display: none;
    }
    .iframe-container {
      margin-right: $block-padding-left-and-right;
      margin-left: $block-padding-left-and-right;
    }
  }

  .iframe-container {
    position: relative;
    border-radius: 1rem;
    // iframe cover allows mouse events to remain on the hosted page during drag and drop:
    .iframe-cover {
      position: absolute;
      height: 0;
      width: 0;
      &.active {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  // Using our own custom placeholder to get around large draggable limitations, but still need to keep this on the page:
  [data-rbd-placeholder-context-id] {
    display: none !important;
  }

  // The element left on the page while dragging (not the clone that follows the mouse)
  .is-dragging {
    > * {
      opacity: 0.5;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($blue, 0.05);
    }
  }

  // When in edit mode, row-gap is disabled so that droppable zones are touching flush.  Need to account for spacing:
  [data-rbd-droppable-id='top'] {
    padding-bottom: 1.5rem;
  }
  [data-rbd-droppable-id='bottom'] {
    padding-top: 1.5rem;
  }

  // mobile layout to fix cutoff
  @media screen and (max-width: 600px) {
    // pulled styling directly from full page
    display: flex;
    flex-direction: column;

    // remove excess padding
    &:not(.edit-mode) {
      padding: 0;
    }

    // make side block margin consistent
    // important because named layout rules took precedence
    .side-blocks {
      margin: 3rem !important;
    }

    // make iframe-container full width-ish
    .iframe-container {
      margin-right: $block-padding-left-and-right;
      margin-left: $block-padding-left-and-right;
    }
  }
}

// Top-level style since the drag preview is rendered in a portal at a top level of the DOM
.drag-preview-item {
  left: $block-padding-left-and-right;
  width: calc(#{$EDIT_PAGE_WIDTH} - #{$block-padding-left-and-right} * 2);

  // Calculations take into account what the padding should look like across the various layout sections:
  &.LeftHalf {
    left: calc(50% + #{$block-padding-left-and-right});
    width: calc(
      1 / 2 * #{$EDIT_PAGE_WIDTH} - #{$block-padding-left-and-right} * 2
    );
  }
  &.LeftThird {
    left: calc(33.33% + #{$block-padding-left-and-right});
    width: calc(
      2 / 3 * #{$EDIT_PAGE_WIDTH} - #{$block-padding-left-and-right} * 2
    );
  }
  &.RightHalf {
    left: $block-padding-left-and-right;
    width: calc(
      1 / 2 * #{$EDIT_PAGE_WIDTH} - #{$block-padding-left-and-right} * 2
    );
  }
  &.RightThird {
    left: $block-padding-left-and-right;
    width: calc(
      2 / 3 * #{$EDIT_PAGE_WIDTH} - #{$block-padding-left-and-right} * 2
    );
  }
  > div {
    box-shadow: 0rem 0.25rem 2.125rem rgba(0, 0, 0, 0.1);
    background-color: white;
    opacity: 0.9;
    position: absolute;
    width: 100%;
    border: 2px solid $blue;
    border-radius: 4px;
  }
}
