@import '_variables';

.impact {
  align-items: center;
  border: 1px solid $primary;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  overflow: hidden;
  position: relative;

  &-background {
    background-color: $primary;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &-content {
    padding: 2rem 1.5rem 2rem 2rem;
    z-index: 2;
  }

  &-icon {
    color: $primary;
    margin-right: 1rem;

    &.MuiSvgIcon-root {
      font-size: 2.5rem;
    }
  }

  &-statement {
    &.MuiTypography-h2 {
      color: $primary;
      font-family: $body-font;
    }
  }

  .left-ellipse {
    &.MuiSvgIcon-root {
      font-size: 7rem;
      fill: $primary;
      fill-opacity: 0.2;
      position: absolute;
      left: 0;
      stroke-width: 0;
      top: 0;
    }
  }

  .right-ellipse {
    &.MuiSvgIcon-root {
      bottom: 0;
      font-size: 7rem;
      fill: $primary;
      fill-opacity: 0.2;
      position: absolute;
      right: 0;
      stroke-width: 0;
      transform: rotate(0.5turn);
    }
  }
}
