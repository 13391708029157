// Add mobile styling to DatePicker modal window
@media (max-width: 600px) {
  .date-picker-box {
    // Make sure we can absolutely position dialog
    position: relative;

    // Move dialog over input so that it's always on screen.
    .DatePickerDialog {
      position: absolute;
      bottom: auto;
      top: -2.5rem;
    }
  }
}
