@import '_variables';

#GF-BillingInfo {
  margin-top: 0.5rem;

  // This being a child, I don't think it targets anything since the component itself has both the id and className
  .GF-BillingInfo {
    &__toggle--container,
    &__toggle-button--container {
      width: 100%;
      display: flex;

      .toggleButton {
        color: $primary;
        background-color: $accent;
        border: 1px solid $primary;

        &:hover {
          background-color: $primary;
          color: $accent;
        }

        &.selected {
          background-color: $primary;
          color: $accent;
        }
      }
    }

    &__prefix--container .MuiFormControl-root {
      width: 100%;
    }

    &__select--container .MuiFormControl-root {
      width: 100%;
    }

    &__textfield--confirm-email {
      position: absolute;
      left: -900rem;
    }
  }
}
