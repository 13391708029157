@import '_variables';
@import '_mixins';

.GF-CCPaymentInfo .GF-CCPaymentInfo {
  &__heading {
    @include h5-styles;
    color: $gray;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    line-height: 1.5rem;
    &--container {
      @include mobile-styles {
        svg:first-of-type {
          margin-left: auto;
        }
      }
    }
  }
}
