@import '_variables';

#GF-EmailOptIn {
  .GF-EmailOptIn__checkbox,
  .GF-EmailOptIn__checkbox--selected {
    margin-left: -0.5rem;

    .MuiSvgIcon-root {
      font-size: 1.75rem;
    }
  }

  .GF-EmailOptIn__heading {
    margin-bottom: 0.25rem;
  }
}
