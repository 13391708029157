@import '_variables';

.tribute-modal {
  // setting fixed width to prevent MUI anomaly where
  // modal expands as form fields are filled out,
  // even if size of inputs is fixed
  width: 40rem;
  margin: 0 !important;

  .tribute-modal-circular-buttons {
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 10rem;
    justify-content: center;
    width: 10rem;

    &.selected {
      border: 2px solid $primary;

      .MuiTypography-root {
        color: $primary;
      }
    }
  }

  .tribute-purpose-container {
    align-items: center;
    display: flex;
    flex-direction: column;

    .tribute-purpose-button-container {
      display: flex;
      justify-content: space-around;
      margin-top: 2.5rem;
      width: 100%;

      .tribute-purpose-button {
        gap: 1rem;
        padding-bottom: 1rem;

        .MuiSvgIcon-root {
          height: 3rem;
          stroke: $primary;
          width: 3rem;
        }
      }
    }
  }

  .tribute-info-container {
    h3 {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 1.4rem;
    }

    #menu-tributeState,
    #menu-tributeCountry,
    #menu-tributePrefix {
      .MuiPaper-root {
        // These need the !important designation because top/left values
        // are being added inline by something, possibly within MUI.
        top: 0 !important;
        left: 0 !important;
      }
    }

    .custom-select-menu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
      max-height: 18rem;
    }

    .tribute-ecard-custom-message {
      margin-bottom: 1.2rem;
    }
  }

  .tribute-ecard-optin-container {
    .tribute-ecard-optin-button-container {
      display: flex;
      justify-content: space-around;
      margin-top: 2.5rem;
      width: 100%;
    }
  }

  .tribute-ecard-container {
    .tribute-ecard-instruction {
      margin-bottom: 2.5rem;
      font-size: 1.125rem;
      line-height: 1.4rem;
    }

    .ecard-image-container {
      display: flex;
      margin-bottom: 2.5rem;

      .ecard-image {
        flex: 1;

        &:not(.selected) img {
          border: 2px solid white;
        }

        &.selected img {
          padding: 4px;
          border: 4px solid $primary;
        }

        &:not(:last-of-type) {
          margin-right: 1.125rem;
        }
      }
    }

    .tribute-ecard-custom-message,
    .tribute-ecard-email {
      margin-bottom: 1.2rem;
    }
  }
}
