@import '_mixins';
@import '_variables';

#GF-Blocks {
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  @include mobile-styles {
    padding: 1.5rem;
  }

  // When doing page segments, need to apply spacing to wrapper divs except the top one
  > .gf-page-segment > div:not(:first-of-type) {
    margin-top: $block-margin;
  }

  .GF-BillingInfo__heading {
    @include h5-styles;
    color: $gray;
    margin-bottom: 1rem;
  }

  .GF__GridInputWrapper {
    @include mobile-styles {
      &.MuiGrid-item {
        padding-top: 1rem;
      }
    }

    &.no-padding-top {
      padding-top: 0 !important;
    }

    &.hide-on-xs {
      @include mobile-styles {
        display: none;
      }
    }
  }

  .GF-Submit {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2.5rem;

    .submit-button-wrapper {
      flex-direction: column;
      display: flex;
    }

    > * {
      flex: 1 1;
    }

    .error-message,
    .validation-message {
      text-align: center;
    }
  }

  .block-dropzone + .GF-Submit {
    margin-top: 0;
  }

  .gf-hidden-block {
    display: none;
  }
}
