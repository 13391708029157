@import '_variables';

.MuiTypography-root.TableCell {
  &__content {
    width: fit-content;
    color: $dark-gray;
    line-height: 1.5;

    // word wrap cells in PlaceholderTable
    white-space: pre-wrap;

    &--placeholder {
      background-color: $lighter-gray;
    }
  }
}
