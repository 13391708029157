@import '_variables';
#GF-RecurringOptions .GF-RecurringOptions {
  &__button-container {
    width: 100%;
    display: flex;
  }
  &__button {
    color: $primary;
    background-color: $accent;
    border: 1px solid $primary;
    &:hover {
      background-color: $primary;
      color: $accent;
    }
    &--selected {
      background-color: $primary;
      color: $accent;
    }
  }
}

.indefinite-end-notice-message {
  margin-top: 0.5rem !important;
}
