@import '_variables';

.page-break-block {
  .page-break-separator {
    display: flex;
    flex-direction: row;
    color: $blue;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    &:before,
    &:after {
      content: '';
      border-bottom: 1px $blue solid;
      flex: 1 1;
      margin: auto;
    }
    &:before {
      margin-right: 0.4375rem;
    }
    &:after {
      margin-left: 0.4375rem;
    }
  }
  .page-break-actions {
    display: flex;
    gap: 1rem;
    button {
      // Styling for the next and back buttons
      flex: 1 1;
      &.next-button {
        color: $accent;
        background-color: $primary;
      }
      &.back-button {
        color: $primary;
        background-color: $accent;
        border: 1px solid $primary;

        &:hover {
          background-color: $primary;
          color: $accent;
        }
        &--selected {
          background-color: $primary;
          color: $accent;
        }
      }
    }
  }

  .validation-message {
    text-align: center;
  }
}
