@import 'mixins';
@import 'variables';

.GF-RecurringGiftPrompt {
  .MuiPaper-root {
    position: absolute;
    bottom: 0;
  }

  &__title span {
    font-family: $body-font;
  }

  &__content-container {
    display: flex;
  }

  &__header.MuiTypography-root {
    font-family: $body-font;
    line-height: 1.25;
  }

  &__description.MuiTypography-root {
    font-family: $body-font;
    line-height: 1.75;
    margin-bottom: 0.5rem;
  }

  &__recurring-amount-label.MuiTypography-root {
    font-family: $body-font;
    line-height: 3;
    font-weight: 500;
  }

  &__recurring-amount-value {
    margin-top: 0.5rem;
    width: max-content;
    font-family: $body-font;
    font-size: 1.125rem;
    line-height: 1.25;
    font-weight: 900;
  }

  // error message
  p.MuiFormHelperText-root {
    white-space: nowrap;
  }

  &__buttons {
    flex-direction: row;
  }

  &__button {
    font-family: $body-font;

    &:not(:first-of-type) {
      margin-left: 1.5rem;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 7.5rem;
  }
}

@include mobile-styles {
  .GF-RecurringGiftPrompt {
    &__content-container {
      display: block;

      svg.GF-RecurringGiftSvg {
        display: none;
      }
    }

    &__header.MuiTypography-root {
      font-family: $body-font;
      font-size: 1.5rem;
    }

    .MuiDialogContent-root {
      padding: 2.5rem 1.5rem;
    }

    &__recurring-amount-label {
      margin-top: 1rem;
    }

    &__recurring-amount-value.MuiTypography-root {
      display: block;
      line-height: 3;
    }

    &__buttons {
      flex-direction: column;
      padding: 1.5rem;
    }

    &__button {
      width: 100%;
      &:not(:first-of-type) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}
