@import '_variables';
@import '_mixins';

.GF-DesignationPicker {
  margin-bottom: 2rem;
  margin-top: 2rem;
  .GF-DesignationPicker {
    &__Container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__Title {
      display: flex;
      gap: 1rem;

      h5 {
        font-size: 1rem;
        font-family: $body-font;
        &:nth-child(1) {
          flex-grow: 2;
        }

        &:nth-child(2) {
          width: 40%;
        }
      }
    }

    &__Row {
      display: flex;
      gap: 1rem;
      @include mobile-styles {
        flex-direction: column;
        margin-bottom: 1rem;
      }
      .MuiAutocomplete-root {
        flex-grow: 2;
      }
      p {
        width: 40%;
        display: flex;
        align-items: center;
      }
      .GF-DesignationPicker__Price {
        flex-grow: 2;
        &--Container {
          width: 40%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mobile-styles {
            width: 100%;
          }
        }
      }
      .GF-DesignationPicker__Delete {
        margin: 0.5rem;
        margin-top: 0.8rem;
        align-self: flex-start;
        stroke: $primary;
        cursor: pointer;
      }
    }

    &__Amount {
      font-family: $body-font;
      &--Label {
        @include mobile-styles {
          display: none;
        }
      }
    }

    &__AddDesignation {
      color: $primary;
      cursor: pointer;
      width: fit-content;
      font-family: $body-font;
      background: none !important;
      padding: 0;
      font-weight: bold;
      height: auto;
    }

    &__DonationAmount {
      width: 100%;
      position: relative;
      &--Background {
        box-sizing: border-box;
        background-color: $primary;
        opacity: 0.1;
        width: 100%;
        height: 4rem;
        padding: 1.5rem;
        border-radius: 0.5rem;
      }
      &--Text {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        display: flex;
        justify-content: space-between;
        p {
          color: $black;
        }
      }
    }

    &__Error {
      color: $red;
    }
  }
}
