#ApplePay-Icon,
#Echeck-Icon,
#GooglePay-Icon,
#Paypal-Icon {
  width: 100%;
  stroke: none;
}

.GF-PaymentOptions__button--googlepay {
  border: none !important;
  padding: 0 !important;
  border-radius: 100px !important;

  svg {
    height: 2em;
  }

  svg path#Base_1_ {
    fill-opacity: 0;
  }

  &.GF-PaymentOptions__button--selected {
    border: 4px solid var(--primary-color) !important;
    background-color: white !important;

    svg path {
      fill: revert-layer !important;
      color: inherit !important;
    }
  }
}

.GF-PaymentInfo__cc-icon- {
  &inactive,
  &active {
    width: 2.125rem;
    height: 1.5rem;
  }
}
