@import '_variables';

#GMS-ComboBox {
  padding-left: 0.5rem;
  padding-top: 0.4rem;
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-option,
  .MuiAutocomplete-noOptions {
    // font-family needs to be $system-stack
    // in gms and $body-font in giving form
    font-family: $body-font;
    padding: 1rem !important;
    margin: 0.5rem !important;
  }
  .Mui-focused {
    border-radius: 0.5rem !important;
    background-color: $lighter-gray !important;
  }
}
