@import '_variables';

.GF-ThankYou {
  border-radius: 1rem;
  background-color: white;
}

#ThankYou-Blocks {
  padding: 3rem;
}

.GF-ThankYou__header {
  padding: 1rem;
}

.temp-block {
  padding: 2rem;
}
