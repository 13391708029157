@import '_variables';

// Styling for when an item is being dragged.  Based on class on an element higher in the tree:
.drag-active {
  .edit-options-wrapper {
    margin-top: 0;
    &.not-draggable {
      margin-top: $block-margin;
    }
    &:hover:not(.dragging-block) {
      border: none;
      border-radius: 0;
      .edit-options-buttons-container {
        display: none;
      }
    }
  }

  .block-dropzone {
    height: $block-margin;
    padding-top: 0;
    padding-bottom: 0;
    cursor: copy !important;
    &.closest-dropzone {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      height: 2.5rem + 4rem;

      div {
        border: 2px dashed $blue;
        border-radius: 4px;
        background-color: #f4f7ff;
        height: 100%;
      }
    }
  }
}

.edit-options {
  &-wrapper {
    position: relative;
    border: 2px solid transparent;
    margin-top: $block-margin;
    &:hover,
    &.edit-options-wrapper--selected {
      border: 2px solid $blue;
      border-radius: 4px;
      .edit-options-buttons-container {
        display: block;
        z-index: 2; // Without z-index value, the child block will cover up the buttons
      }
    }

    &.compress-wrapper .edit-options-buttons-container {
      top: -1px;
      right: -1px;
    }

    &.header-edit-wrapper {
      margin-top: 0;
    }

    &.dragging-block {
      border: 2px solid $blue;
      margin-top: $block-margin;
      .edit-options-buttons-container {
        display: none;
      }
      // Keep the margin-top for any blocks immediately below the dragged item:
      & + .edit-options-wrapper {
        margin-top: $block-margin;
      }
    }

    .drag-icon-button {
      cursor: move;
    }

    .edit-options-icon {
      stroke: white;
    }
  }
  &-buttons-container {
    position: absolute;
    top: -13px;
    right: -12px;
    background-color: $blue;
    border-radius: 4px;
    display: none;
    & svg {
      width: 26px;
      height: 26px;
    }
  }
}
