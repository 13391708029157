@import '_variables';
@import '_mixins';

.GF-Header {
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  padding: 3rem 4rem;
  background-color: $primary;
  @include mobile-styles {
    padding: 3rem 1.5rem;
  }
}
