// MAR - bringing @variables into mixins causes some issues in certain cases when @variables is already brought in
// noting this here so we can address in the future, but for now as mixins is a small file, I'm switching
// the few variables here to be static.

@mixin mobile-styles {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin h5-styles {
  font-weight: 700;
  font-size: 1rem;
  color: #020026;
  line-height: 1;
}
