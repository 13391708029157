@import '_mixins';
@import '_variables';

.email-container {
  padding: 3rem;
}

// Shows background-color on placeholders when viewing from the receipt editor
// Does not show on actual email
.email-details-placeholder {
  background-color: $lighter-gray;
  font-weight: 700;
}
