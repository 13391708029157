@import '_variables';
@import '_mixins';

@mixin font-styles($font-size, $font-weight) {
  color: $black;
  margin: 0;
  font-size: $font-size;
  font-weight: $font-weight;
}

.HP-Header {
  width: 100%;
  min-height: 14.0625rem;
  .HP-Header-banner-html-wrapper {
    padding: 3rem 4rem;
    min-height: 14.0625rem;
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    img {
      max-width: 100%;
    }
    h1 {
      @include font-styles(2rem, 900);
    }
    h2 {
      @include font-styles(1.5rem, 900);
    }
    h3 {
      @include font-styles(1.125rem, 900);
    }
    h4 {
      @include font-styles(1rem, 900);
    }
    h5 {
      @include font-styles(1rem, 700);
    }
    h6 {
      @include font-styles(0.75rem, 700);
    }
    p,
    ol,
    ul {
      color: $black;
      font-size: 1rem;
      font-weight: 400;
      line-height: 28px;
      margin: 0;
    }
    &:after {
      clear: both;
    }

    @include mobile-styles {
      padding: 1rem 1.333rem;
      min-height: 8rem;

      &.disabled {
        display: none;
      }
    }
  }
}
