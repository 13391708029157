@import '_variables';

#GF-GiftOptions {
  .GF-GiftOptions {
    &__heading {
      margin-bottom: 0.5rem;
    }
    &__button {
      background-color: $accent;
      color: $primary;
      border: 1px solid $primary;
      &:hover {
        background-color: $primary;
        color: $accent;
      }
      &--selected {
        background-color: $primary;
        color: $accent;
        border-color: $accent;
      }
    }
    &__custom-amount {
      .MuiOutlinedInput-root {
        // Clarify with Design
        background: $accent;
        input {
          color: $primary;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $primary;
      }
      &--selected .MuiOutlinedInput-notchedOutline {
        border-width: 2px !important;
      }
      // Clarify these with design!!
      &--selected.GF-GiftOptions__custom-amount--valid {
        .MuiOutlinedInput-root {
          background: $primary;
          input {
            color: $accent;
          }
        }
        fieldset {
          border-color: $accent;
        }
      }
      &--valid .MuiInputBase-input {
        color: $primary;
        font-family: Roobert;
        font-weight: 900;
      }
      &--error .MuiOutlinedInput-notchedOutline {
        border-color: $red;
      }
      input {
        text-align: center;
      }
    }
    &__subtext {
      padding-bottom: 0.5rem;
    }

    &__tag {
      color: $primary;
      padding-bottom: 0.5rem;
    }
  }
}
