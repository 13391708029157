@import '_variables';

#GF-PaymentOptions .GF-PaymentOptions {
  &__button {
    height: 50px;
    width: 90px;
    padding: 0 12px;
    margin: 0 1rem 0.5rem 0;
    &:hover {
      background-color: $primary;
      color: white;
      svg path {
        fill: white;
      }
    }
    &--selected {
      background-color: $primary;
      color: white;
      svg path {
        fill: white;
      }
    }
  }
}
