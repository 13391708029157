@import '_variables.scss';

.gf-corporate-matching {
  .corporate-matching-heading {
    font-family: $body-font;
  }

  .corporate-matching-prompt {
    font-family: $body-font;
    margin-top: 0.5rem;
  }

  #dd-company-name-input {
    margin-top: 1rem;

    /* Force removal of grey background on entries */
    .dtd-dropdown-is-selected * {
      background: none !important;
    }

    .dtd-streamlined-plugin {
      .dtd-search-box .dtd-search-input {
        border: 1px solid #dadce8;
        border-radius: 0.5rem;
        padding: 10px 1rem;
        font-size: 1rem;
        line-height: 1.75rem;
        color: $black !important;
        font-family: $body-font;

        &:hover,
        &:active {
          border: 1px solid $dark-gray;
          background-color: $lighter-gray !important;
        }
      }

      div.Select-menu-outer {
        margin-top: 0.25rem;
        box-shadow: 0px 2px 8px rgba(89, 102, 132, 0.18);
        border: 1px solid $lighter-gray;

        div.Select-menu {
          padding: 0.5rem 0.75rem;

          div.Select-option {
            background-color: #ffffff !important;
            border-radius: 0.5rem;
            font-family: $body-font;

            &:hover {
              background-color: $lighter-gray !important;
            }

            & span,
            & span b,
            em {
              color: $black !important;
              font-size: 1rem;
              line-height: 1.75rem;
            }
          }
        }
      }

      .dtd-company-selected {
        font-family: $body-font;

        .wrongcompany {
          display: block;
          margin-top: 0.5rem;

          a {
            color: $primary;
            background-color: $accent;
            border: 1px solid $primary;
            border-radius: 0.5rem;
            margin-top: 1rem;
            padding: 0 1.5rem;
            height: 3rem;
            text-decoration: none;
            font-weight: 900;

            display: inline-block;
            line-height: 3rem;

            &:hover {
              color: $accent;
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}
